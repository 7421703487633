import './bootstrap';

import { createApp, h } from 'vue';
import { createInertiaApp, InertiaLink } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import AdmContentBox from '@/Components/Adm/ContentBox.vue';
import AppContentBox from '@/Components/App/ContentBox.vue';

import AdmLayout from '@/Layouts/AdmLayout.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import { Head } from '@inertiajs/inertia-vue3';
import Header from '@/Components/Adm/Header.vue';
import ATable from '@/Components/Adm/Table.vue';
import ATd from '@/Components/Adm/Td.vue';
import ATh from '@/Components/Adm/Th.vue';
import TextArea from '@/Components/TextArea.vue'
import Button from '@/Components/Button.vue';
import CheckBox from '@/Components/Checkbox.vue';
import { Link } from '@inertiajs/inertia-vue3';
import Input from '@/Components/Input.vue';
import Label from '@/Components/Label.vue';
import Select from '@/Components/Select.vue';
import Datepicker from '@/Components/Datepicker.vue';
import moment from 'moment-timezone';
import can from './Plugins/can.js'; 
import ContentHeader from '@/Components/App/ContentHeader.vue'; 
import naive from 'naive-ui';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import '../css/app.css';


moment.tz.setDefault(0);



export default createInertiaApp({
    title: (title) => `${title} - Prowi`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),

    setup({ el, app, props, plugin }) {

        const VueApp =  createApp(
            { 
                render: () => h(app, props) 
            }
        )
        
        VueApp.config.globalProperties.$filters = {
            dateTimeFromNow(value){
                return moment.utc(value).local().fromNow();
            },
            dateTime(value) {
                return moment(value).utcOffset(0).calendar();
            },
            dateTimeFixed(value){
                return moment(value).utcOffset(0).format("DD.MM.YY");
            }, 
            formatCurrency(value, currency = 'DKK') {
                let formatter = new Intl.NumberFormat('da-DK', {
                    style: 'currency',
                    currency: currency,
                });

                return formatter.format(value)
            }, 
            largeNo(value){
                if (!value) return '0.00';
                const num = parseFloat(value);
                if (num >= 1000000000) {
                    return (num / 1000000000).toFixed(2) + 'B';
                }
                if (num >= 1000000) {
                    return (num / 1000000).toFixed(2) + 'M';
                }
                if (num >= 1000) {
                    return (num / 1000).toFixed(1).replace('.0', '') + 'K'
                }
                return num.toFixed(2); 
            }   
        }
                    
        VueApp.use(naive);


        VueApp.use(plugin)
            .mixin({ 
                methods: { route, can }, 
                components: {
                    InertiaLink, 
                    AdmContentBox, 
                    AdmLayout, 
                    AppLayout,
                    Head, 
                    Header, 
                    ATable, 
                    ATh, 
                    ATd, 
                    Button,
                    CheckBox,
                    Link,
                    AppContentBox,
                    TextArea,
                    Label, 
                    Input,
                    Select,
                    Datepicker,
                    ContentHeader
                } })
            .mount(el);


        return VueApp;
    },
});



InertiaProgress.init({ color: '#4B5563' });


